import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import axios from "axios";
import AWS from "aws-sdk";

import { store } from "../index";
import * as UserActions from "../actions/userAction";
import { AUTH_TOKEN } from "../localStorage/constants";

const httpLink = createHttpLink({
  uri: "https://api.amckey.com/graphql",
});
// "http://localhost:4000/graphql",

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token =
    store.getState().UserStore.authToken || localStorage.getItem(AUTH_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions, message }) => {
      if (extensions.exception) {
        if (extensions.exception.code === "TOKEN_REFRESHED_SESSION_EXPIRED") {
          const showMessage = false;
          store.dispatch(UserActions.logout(showMessage));
        }
      }
    });
});

const axiosClient = axios.create({
  baseURL: "https://upload.amckey.com/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(function(config) {
  try {
    const token = store.getState().UserStore.authToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (err) {
    console.log(err);
  }
  return config;
});

const spacesEndpoint = new AWS.Endpoint("sgp1.digitaloceanspaces.com");
const S3Client = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "YZAYQTIYLQLPZNO7QNGL",
  secretAccessKey: "CYWzAlxECFTps43rI2SsSrHOn/wkxsQcqBaOf0/81bA",
});

export default {
  SESSION_TIMEOUT: 60 * 1000 * 1500,
  client: new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  }),
  S3Client,
  axiosClient,
  API: {
    LOOKUP: "https://svc-stage-lookup.dacsee.io",
    BOOKING: "https://svc-stage-booking.dacsee.io",
    PAYMENT: "https://svc-stage-payment.dacsee.io",
  },
  recaptcha_id: "6Lf6eaUZAAAAAI76PauZBCXHoYnBXf4_vRWULXbc",
  company_id: "5e8f1921087434edeb4a0f2b",
  tinyMceApiKey: "gxkqe8701zhxlfb6op0r829wpnesyexcmensrtn2xqp730vd",
  PAYPAL_CLIENT_ID:
    "AejXtrc3i5WN6RDEiwJEjJSAbEUbUO52SfAOa3tVofLeCjtJS2_5yg3ARh_nLksKFy6xqtqqxaslF7aU",
};
